import React from "react";
import CrossDock from "../../assets/crossdock.jpg";
import WareHousing from "../../assets/warehousing.jpg";
import FreightBroker from "../../assets/broker.jpg";
import FtlLtl from "../../assets/ftl_ltl.jpg";

const services = () => {
  return (
    <div name="services" className="mx-auto p-4 py-6 max-w-auto">
      <h1 className="text-center font-bold text-5xl my-4 mb-6 text-blue-900">
        We offer a complete solution to your logistical needs
      </h1>
      <div className="grid md:grid-cols-4 gap-6 justify-center">
        <div className="border-2 border-black drop-shadow-lg m-2 text-center w-[420px] ">
          <img
            src={FtlLtl}
            alt="CrossDock"
            className="h-[420px] w-auto object-cover"
          />
          <h2 className="flex bg-blue-900 h-[60px] justify-center py-[10px] text-white font-medium text-2xl">
            LTL/FTL
          </h2>
        </div>
        <div className="border-2 border-black drop-shadow-lg  m-2 text-center w-[420px] ">
          <img
            src={WareHousing}
            alt="Warehousing"
            className="h-[420px] w-auto object-cover"
          />
           <h2 className="flex bg-blue-900 h-[60px] justify-center py-[10px] text-white font-medium text-2xl">
            Warehousing
          </h2>
        </div>
        <div className="border-2 border-black drop-shadow-lg  m-2 text-center w-[420px] ">
          <img
            src={CrossDock}
            alt="CrossDock"
            className="h-[420px] w-auto object-auto"
          />
           <h2 className="flex bg-blue-900 h-[60px] justify-center py-[10px] text-white font-medium text-2xl">
            Cross Dock
          </h2>
        </div>
        <div className="border-2 border-black drop-shadow-lg  m-2 text-center w-[420px]">
          <img
            src={FreightBroker}
            alt="CrossDock"
            className="h-[420px] w-auto object-cover hover:opacity"
          />
           <h2 className="flex bg-blue-900 h-[60px] justify-center py-[10px] text-white font-medium text-2xl">
            Freight Broker
          </h2>
        </div>
      </div>
    </div>
  );
};

export default services;
