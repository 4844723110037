import React from "react";
import WareHouseQuote from "../../assets/warehousingQuotes.jpg";
import { AiOutlineCheck } from "react-icons/ai";

const WareHouse = () => {
  const handleClicks = () => {
    window.location.href = "https://calendly.com/megawarehouse/mwappointment";
  };
  return (
    <div name="warehouse" className="max-w-[1604px] mx-auto px-4">
      <div className="flex flex-col justify-center">
        <h1 className="font-bold text-5xl my-6 text-blue-900 text-center">
          Warehousing
        </h1>
        <div>
          <p className="text-2xl font-medium text-center">
            We have crossdocking, long term and short term Warehousing options
            Please click below to schedule a consultation today!
          </p>
        </div>
        <div className="flex flex-col md:flex-row text-4xl font-medium justify-center my-12 mx-36
         gap-6">
          <img
            src={WareHouseQuote}
            alt="WareHouse Quote"
            className="order-last md:order-first max-h-[500px] max-w-[500px] object-cover drop-shadow-lg"
          />

          <div>
            <div className="">
              <ul className="flex flex-col">
                <li className="flex">
                  <AiOutlineCheck
                    size={40}
                    className="text-blue-900 font-bold drop-shadow-md mb-6"
                  />
                  Multiple Locations
                </li>
                <li className="flex">
                  <AiOutlineCheck
                    size={40}
                    className="text-blue-900 font-bold drop-shadow-md mb-6"
                  />
                  Crossdocking
                </li>
                <li className="flex">
                  <AiOutlineCheck
                    size={40}
                    className="text-blue-900 font-bold drop-shadow-md mb-6"
                  />
                  Hazmat Certifed
                </li>
                <li className="flex">
                  <AiOutlineCheck
                    size={40}
                    className="text-blue-900 font-bold drop-shadow-md"
                  />
                  Competitive Pricing
                </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
        <div className="flex justify-center">
        <button
          onClick={handleClicks}
          className="text-white rounded-full bg-blue-900 p-4 mx-8 md:mx- my-4 hover:scale-110 hover:Ease-in duration-300"
        >
          Schedule Today!
        </button>
        </div>
      
    </div>
  );
};

export default WareHouse;
