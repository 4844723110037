import React from "react";
import Woody from "../../assets/peterbilt_woody.jpg";

const About = () => {
  return (
    <div name="about" className="max-w-[1640px] mx-auto my-2 flex flex-col">
      <div className="text-5xl my-4">
        <h1 className="font-bold text-blue-900 text-center py-">
          We are MEGA Logistics
        </h1>
      </div>
      <div className="font-medium text-xl md:text-2xl lg:text-4xl flex flex-col md:flex-row gap-4 mx-24">
        <div className="justify-center m-12">
          <p className="">
            Our mission is to to give cost effective and effecient shipping
            solutions anywhere in the country. Be it FTL, LTL, warehousing or
            crossdocking, we have solutions not only to save you time and money,
            but also to provide you with the best shipping service.
          </p>
          <p className="my-4">
            Open since 2012 we have the experience our Brokerage team works to
            get you the fastest shipping along with the most compeitive rates
            possible. Our team is ready to get you a solution to your
            logisitical and shipping needs.
          </p>
        </div>
          <img src={Woody} alt="Peterbilt Woody" className="h-[400px] w-full object-cover" />
      </div>
      
    </div>
  );
};

export default About;
