import React from "react";
import { Link } from "react-scroll";

import UsTruckFlag from "../../assets/footer_flag_truck.png";

const Footer = () => {

  const handleClick = () => {
    window.location.replace("https://form.jotform.com/231635679502056");
  };

  const handleClicks = () => {
    window.location.replace("https://calendly.com/megawarehouse/mwappointment");
  };

  return (
    <div className="max-w-[1640] h-full mx-auto flex flex-row justify-between border-t-black border-2 bg-blue-900">
      <div className="flex flex-col font-medium text-2xl px-6 pb-2 py-12">
        <h1>
          <span className="font-bold">MEGA</span> Logistic Services
        </h1>
        <p>601 Sroufe st. Ste 200</p>
        <p>Ligonier, IN </p>
        <p>46767</p>
        <p>(574)635-1312</p>
        <p>MC953505</p>
      </div>
      <div className="font-medium grid grid-cols-1 text-center text-2xl py-12">
        <Link
          to="contact"
          smooth={true}
          duration={500}
          className="hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
        >
          Contact Us
        </Link>
        <Link
          to="about"
          smooth={true}
          duration={500}
          className="hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
        >
          About Us
        </Link>
        <Link
          to="warehouse"
          smooth={true}
          duration={500}
          className="hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
        >
          Request a Quote
        </Link>
        <Link
          to="services"
          smooth={true}
          duration={500}
          className="hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
        >
          Services
        </Link>
        <h1
          onClick={handleClicks}
          className="hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
        >
          Warehouse Appointment
        </h1>
        <h1
          onClick={handleClick}
          className="hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
        >
          Carrier Packet
        </h1>
      </div>
      <div className="py-14 px-12 mx-6">
        <img
          src={UsTruckFlag}
          alt="Us Truck"
          className="h-[150px] w-[150px] m-12"
        />
      </div>
    </div>
  );
};

export default Footer;
