import React, { useState } from "react";
import { HiOutlineMenu } from "react-icons/hi";
import { Link } from "react-scroll";

const NavBar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const handleClick = () => {
    window.location.replace("https://form.jotform.com/231635679502056");
  };

  const handleClicks = () => {
    window.location.replace("https://calendly.com/megawarehouse/mwappointment");
  };

  return (
    <div className=" sm:visible flex font-medium justify-between max-w-auto mx-auto bg-black/40 p-8">
      <div>
        <h1>
          <span className="font-bold text-blue-900 drop-shadow-md text-3xl">
            MEGA
          </span>
          <span className="font-medium text-3xl">Logistic Services</span>
        </h1>
      </div>
      <div className="md:flex md:flex-row gap-6 text-2xl hidden md:visible">
        <Link
          to="services"
          smooth={true}
          duration={500}
          className="hover:text-blue-900 hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
        >
          Services
        </Link>
        <h1
          onClick={handleClicks}
          className="hover:text-blue-900 hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
        >
          Warehouse Appointment
        </h1>
        <Link
          to="warehouse"
          smooth={true}
          duration={500}
          className="hover:text-blue-900 hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
        >
          Request a Quote
        </Link>
        <Link
          to="contact"
          smooth={true}
          duration={500}
          className="hover:text-blue-900 hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
        >
          Contact Us
        </Link>
        <Link
          to="about"
          smooth={true}
          duration={500}
          className="hover:text-blue-900 hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
        >
          About Us
        </Link>
        <h1
          onClick={handleClick}
          className="hover:text-blue-900 hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
        >
          Carrier Packet
        </h1>
      </div>
      {/* Hamburger Menu */}
      <div className="md:hidden">
        <button onClick={toggleMobileMenu}>
          <HiOutlineMenu size={40} className="text-blue-900" />
        </button>
        {showMobileMenu && (
          <div className="grid duration-300">
            <Link
              to="services"
              smooth={true}
              duration={500}
              className="hover:text-blue-900 hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
            >
              Services
            </Link>
            <h1
              onClick={handleClicks}
              className="hover:text-blue-900 hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
            >
              Warehouse Appointment
            </h1>
            <Link
              to="warehouse"
              smooth={true}
              duration={500}
              className="hover:text-blue-900 hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
            >
              Request a Quote
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              className="hover:text-blue-900 hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
            >
              Contact Us
            </Link>
            <Link
              to="about"
              smooth={true}
              duration={500}
              className="hover:text-blue-900 hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
            >
              About Us
            </Link>
            <h1
              onClick={handleClick}
              className="hover:text-blue-900 hover:ease-in-out hover:scale-110 duration-300 hover:underline hover:cursor-pointer"
            >
              Carrier Packet
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
