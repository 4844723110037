import NavBar from "./Components/NavBar/NavBar";
import Hero from "./Components/Hero/Hero";
import About from "./Components/About/About";
import Services from "./Components/Services/services";
import WareHouse from "./Components/WareHouse/WareHouse";
import Contact from "./Components/Contact/Contact.jsx";
import Footer from "./Components/Footer/Footer";
import "./App.css";

function App() {
  return (
    <div>
      <NavBar />
      <Hero />
      <About />
      <Services />
      <WareHouse />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
