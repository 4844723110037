import React from "react";
import Truck from "../../assets/truckJumbo.jpg";

const Hero = () => {
  return (
    <div className="max-w-[1640] mx-auto">
      <div className=" max-h-[940px] relative">
        <div className="absolute w-full h-full max-h-[940px] flex flex-col justify-center">
          <h1 className="text-5xl sm:text-6xl md:text-8xl flex justify-center drop-shadow-lg font-bold md:font-medium">
            <span className="font-bold px-4 text-blue-900">MEGA</span>
            Logistic Services
          </h1>
        </div>
        <img
          src={Truck}
          className="max-h-[940px] w-full object-cover"
          alt="Truck"
        />
      </div>
    </div>
  );
};

export default Hero;
