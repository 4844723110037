import React from "react";
import ContactUs from "../../assets/contact_truck.jpg";

const Contact = () => {
  return (
    <div name="contact" className="max-w-[1640px] mx-auto my-[40px] mt-4 flex flex-col">
      <div className="text-5xl my-4">
        <h1 className="font-bold text-blue-900 text-center py-2">Contact Us</h1>
      </div>
      <div className="flex flex-col lg:flex-row justify-center gap-2 mx-32">
        <form
          action="https://getform.io/f/03dd0be1-1596-4ce6-a227-d691972b009e"
          method="POST"
          className="w-full mx-6 order-first"
        >
          <div className="grid grid-cols-2 gap-2">
            <input
              className="border shadow-lg p-3 bg-gray-200/80 text-blue-900"
              type="text"
              name="name"
              placeholder="Name"
            />
            <input
              className="border shadow-lg p-3 bg-gray-200/80 text-blue-900"
              type="email"
              name="email"
              placeholder="Email"
            />
          </div>
          <input
            className="border shadow-lg p-3 w-full my-2 bg-gray-200/80 text-blue-900"
            type="text"
            name="subject"
            placeholder="Subject"
          />
          <textarea
            className="border shadow-lg p-3 w-full bg-gray-200/80 text-blue-900"
            name="message"
            cols="40"
            rows="10"
            placeholder="Message"
          ></textarea>
          <button
            type="submit"
            className="border bg-white rounded shadow-lg p-3  mt-2"
          >
            Submit
          </button>
        </form>
        <img
          src={ContactUs}
          alt="Contact Us"
          className="order-first max-h-[400px] w-[600px] mx-6 drop-shadow-lg object-cover"
        />
      </div>
    </div>
  );
};

export default Contact;
